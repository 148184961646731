module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "388394949304-bmlvpqdssje2dl6kt72g02tcp6da9u3v.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "",
  }
};